.ProductPage-option {
  margin-top: 2em;
}

.ProductPage-option .sub.header {
  font-size: 0.7em !important;
}

.ProductPage-imageCarousel .slick-dots * {
  margin-top: 10px;
}

.ProductPage-imageCarousel .slick-dots .slick-active *:first-child {
  border: 2px solid blue;
}

.ProductPage-imageCarousel--arrow {
  display: block;
  position: absolute;
  width: 30px;
  font-size: 30px;
  margin: 20px;
  z-index: 3;
  cursor: pointer;
  top: 45%;
}

.ProductPage-imageCarousel--arrow svg {
  fill: rgba(195, 85, 0);
}

.ProductPage-imageCarousel--arrow:first-of-type {
  left: 0;
}

.ProductPage-imageCarousel--arrow:last-of-type {
  right: 0;
}

.ProductPage-imageCarousel .slick-list {
  max-width: 75%;
  margin: auto;
  margin-bottom: 2em;
}

.ProductPage-about .segment {
  overflow-x: auto;
}

.ProductPage-about table {
  overflow: scroll;
  table-layout: auto;
  border-collapse: collapse;
}

.ProductPage-about td {
  width: min-content;
}

.ProductPage-option p {
  font-size: 16px;
}

.ProductPage-about h1,
.ProductPage-about h2,
.ProductPage-about h3,
.ProductPage-about h4,
.ProductPage-about h5,
.ProductPage-about h6 {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
}

.ProductPage-option button.secondary {
  margin-top: 5px;
  position: relative;
}

.ProductPage-option button.secondary.disabled {
  background-color: rgba(0, 0, 0, 0.057) !important;
}
.ProductPage-option button.secondary.disabled:before {
  content: "";
  /* width: 100%; */
  top: 50%;
  left: 15%;
  right: 0;
  width: 75%;
  position: absolute;
  transform: rotate(-15deg);
  border-top: 1px solid black;
}

.ProductPage-about table,
.ProductPage-about table th,
.ProductPage-about table td {
  border: 1px solid darkgray;
}
