.FilterBy-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  transition: all ease 0.3s;
  padding: 1.25rem 1.5rem !important;
  font-size: 18px;
  font-weight: 500;
  background: white;
}

.freedom.FilterBy-button {
  background: white;
  border: 0.11em solid rgb(78, 78, 78);
  color: rgb(78, 78, 78);
}

.shaw.FilterBy-button {
  background: white;
  border: 0.11em solid rgb(0, 124, 186);
  color: rgb(78, 78, 78);
}

.FilterBy-button:hover {
  border-left-width: 0.4em;
}

.FilterBy-icon {
  margin-right: 1em;
}

.FilterBy-arrow {
  width: 10px;
  height: 10px;
  border-width: 0px;
  border-left-width: 2px;
  border-top-width: 2px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
  transform: rotate(135deg);
  transition: all ease 0.3s;
}

.freedom.FilterBy-button .FilterBy-arrow {
  border-color: rgb(198, 79, 0);
}

.shaw.FilterBy-button .FilterBy-arrow {
  border-color: rgb(0, 124, 186);
}

.FilterBy-arrowLine {
  width: 20px;
  height: 2px;
  transition: all ease 0.3s;
  margin-right: -10px;
}

.freedom.FilterBy-button:hover .FilterBy-arrowLine {
  background-color: rgb(198, 79, 0);
}

.shaw.FilterBy-button:hover .FilterBy-arrowLine {
  background-color: rgb(0, 124, 186);
}

.FilterBy-button:hover .FilterBy-arrowLine {
  margin-left: 10px;
}

.FilterBy-button:hover .FilterBy-arrow {
  margin-right: -9px;
}

.FilterBy-dimmer {
  background-color: white !important  ;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.FilterBy-dimmer .content {
  color: black !important;
}

.ui.inverted.dimmer {
  background: white !important;
}

.FilterBy-closeIcon {
  cursor: pointer;
  font-size: 1.5em;
  display: flex;
  align-self: flex-end;
}

.FilterBy-checkboxContainer .content {
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.FilterBy-checkbox {
  border: 1px solid black;
  padding: 10px;
  margin-right: 15px;
  color: white;
  cursor: pointer;
  transition: all ease 0.3s;
  display: flex;
  flex-wrap: nowrap;
}

.shaw .FilterBy-checkbox {
  border-radius: 50%;
}

.FilterBy-checkbox.active {
  background-color: rgb(21, 107, 163);
}

.FilterBy-checkbox i {
  margin-right: 0 !important;
}

.FilterBy-textSelected {
  vertical-align: middle;
  color: rgb(198, 79, 0);
}
