.freedom.Navbar {
  background-color: black !important;
}

.shaw.Navbar {
  background-color: white !important;
}

.Navbar-item {
  padding: 1em !important;
  border-bottom: 3px solid transparent !important;
  transform: translateY(3px);
  transition: all ease 0.3s !important;
}

.Navbar-item span {
  transition: all ease 0.3s !important;
}

.Navbar-item svg {
  margin-bottom: -5px;
}

.freedom.Navbar .Navbar-item:hover {
  border-bottom: 3px solid rgba(195, 85, 0) !important;
  background: none !important;
}

.shaw.Navbar .Navbar-item:hover {
  border-bottom: 3px solid rgb(0, 124, 186) !important;
  background: none !important;
}

.Navbar-item.Navbar-item-icon {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.Navbar-item:hover span {
  transform: translateY(-3px);
}

.Navbar-brand {
  margin-top: -15px !important;
}

.Navbar-brand img {
  width: 100px !important;
  margin-right: 15px;
}

.freedom.Navbar .Navbar-item.Navbar-item-icon.Navbar-item-user,
.freedom.Navbar .Navbar-item.Navbar-item-icon.Navbar-item-user i.icon.inverted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.freedom.Navbar .Navbar-item.Navbar-item-icon.Navbar-item-user:hover,
.freedom.Navbar
  .Navbar-item.Navbar-item-icon.Navbar-item-user:hover
  i.icon.inverted {
  color: white !important;
}
