.sidebar {
  max-width: 90vw;
}
.sidebar > .items {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 10px;
}

.sidebar > .image {
  max-width: 25% !important;
  float: "left";
}

.bag-checkout-button {
  display: block;
  width: 90%;
  position: absolute;
  bottom: 15px;
}
.dimmer {
  background-color: rgba(255, 255, 255, 0.7) !important;
  z-index: 100 !important;
}

.container.fluid {
  padding: 0 25px 0 25px;
}

.edit-product-image-del-btn {
  position: absolute;
  display: block;
  transform: translate(-30px, 5px);
}

.error-box {
  position: fixed !important;
  z-index: 9999;
  top: 55px;
  right: 10px;
  margin: 50px;
  width: 300px;
  max-width: 500px;
  max-height: 500px;
}

.error-box > .list {
  max-height: 450px;
  overflow-y: auto;
}

.modals {
  z-index: 999 !important;
}

.admin-layout {
  padding: 0 !important;
}

.login-backsplash {
  height: 100vh;
  background-image: url("../images/f-bg.jpg");
  background-size: cover;
  background-position: right center;
}
.login-page {
  padding: 0px !important;
  margin: 0;
}

.login-page > .grid {
  height: 100vh;
  margin-top: 0px;
}

.login-page > .grid > * > .login-box {
  margin: 5px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .checkout-rail {
    display: none;
    z-index: -1;
  }
}

body.grey {
  background: rgba(245, 245, 245) !important;
}

.ui.primary.button:not(.basic) {
  background-size: 100% 200% !important;
  transition: background-position 0.3s ease !important;
}

.freedom.ui.primary.button:not(.basic) {
  background-size: 100% 200% !important;
  background-image: linear-gradient(
    to bottom,
    rgba(195, 85, 0) 50%,
    black 50%
  ) !important;
  transition: background-position 0.3s ease !important;
}

.shaw.ui.primary.button:not(.basic) {
  background-size: 100% 200% !important;
  background-color: rgb(0, 124, 186) !important;
}

.shaw.ui.primary.button:hover {
  background-image: linear-gradient(
    to right,
    rgb(218, 0, 178),
    rgb(108, 34, 226)
  ) !important;
}

.ui.primary.button:hover {
  background-position: 0 100% !important;
}

.ui.button.basic {
  border-bottom: 4px solid transparent;
}

.ui.button.basic:hover,
.ui.button.basic.active {
  border-bottom: 4px solid;
}
