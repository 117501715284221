.Product-card {
  background: white;
  padding: 2.25em 1.5em;
  border: 2px solid white;
  transition: all 0.3s ease !important;
}

.Product-card:hover {
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.3);
}

.freedom.Product-card:hover {
  border: 2px solid black;
}

.shaw.Product-card:hover {
  border: 2px solid rgb(0, 124, 186);
}

.Product-option {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-top: 25px;
  border-top: 1px solid rgba(222, 222, 222);
  border-bottom: 1px solid rgba(222, 222, 222);
}

.Product-option:not(:first-child) {
  border-left: 1px solid rgba(222, 222, 222);
}

.Product-addToCart {
  background: rgba(195, 85, 0) !important;
}

.Product-addToCart {
  background-size: 100% 200% !important;

  transition: background-position 0.3s ease !important;
}

.freedom.Product-addToCart {
  background-size: 100% 200% !important;
  background-image: linear-gradient(
    to bottom,
    rgba(195, 85, 0) 50%,
    black 50%
  ) !important;
  transition: background-position 0.3s ease !important;
}

.shaw.Product-addToCart {
  background-color: rgb(0, 124, 186) !important;
}

.Product-card:hover .Product-addToCart {
  background-position: 0 100% !important;
}

.Product-image picture {
  width: inherit;
  height: inherit;
}

.Product-image picture img {
  object-fit: cover;
  width: 100%;
  height: 350px;
}
